<template>
  <div :id="chartId" :style="{width:width, height:height}" :option="option" class="luoluqi"></div>
</template>

<script>
import * as echarts from 'echarts/core';

import {
    BarChart, LineChart, PieChart,GaugeChart
} from 'echarts/charts';

import {
    TitleComponent,
    TooltipComponent,
    LegendScrollComponent,
    LegendComponent,
    GridComponent,
    GraphicComponent
    
} from 'echarts/components';

import {
    CanvasRenderer
} from 'echarts/renderers';


echarts.use(
    [TitleComponent, TooltipComponent, LegendComponent, LegendScrollComponent, 
    BarChart, LineChart, PieChart,GaugeChart, CanvasRenderer, GridComponent,GraphicComponent
    ]
);
export default {
  name: 'viewChart',
  props:{
    width: {
      type: String,
      default: '450px'
    },
    height: {
      type: String,
      default: '450px'
    },
    title: {
      type: String,
      default: ''
    },
    chartId: {
      type: String,
      required: true,
      default: 'lineChart'
    },
    option:{
      type: Object,
      default: () => {
        return {
          color: ['#3398DB'],
          xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            data: [100, 300, 200, 500, 1200, 1380, 1520],
            type: 'bar'
          }]
        }
      }
    }
  },
  watch: {
    option () {
      
      this.drawLine()
    },
     height () {
      
      this.drawLine()
    }
  },
  mounted(){
      this.drawLine()
  },
  methods: {
    drawLine(){
      
        let myChart = echarts.init(document.getElementById(this.chartId))
        myChart.setOption(this.option);
    }
  }
}
</script>

<style>
</style>
